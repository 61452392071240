import React from 'react'
import Typography from '@bit/mui-org.material-ui.typography'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

export default function HiWc(props) {
  const data = useStaticQuery(graphql`
    {
      step1: file(relativePath: { eq: "how-it-step-in1.png" }) {
        childImageSharp {
          gatsbyImageData(width: 543, layout: CONSTRAINED)
        }
      }
      step2: file(relativePath: { eq: "how-it-step-in2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 543, layout: CONSTRAINED)
        }
      }
      step3: file(relativePath: { eq: "how-it-step-in3.png" }) {
        childImageSharp {
          gatsbyImageData(width: 543, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <>
      <section className="how-it-works-section">
        <div className="container">
          <div className="how-it-works-bg">
            <h2>
              HOW <span>IT WORKS</span>
            </h2>
            <div className="how-it-step-block">
              <div className="how-it-step">
                <div className="images-step mobile-img-hide">
                  <GatsbyImage
                    image={data.step1.childImageSharp.gatsbyImageData}
                    alt="Cloud Solar step 1"
                  />
                </div>
                <div className="step-content">
                  <div className="images-step mobile-img">
                    <GatsbyImage
                      image={data.step1.childImageSharp.gatsbyImageData}
                      alt="Cloud Solar step 1"
                    />
                  </div>
                  <Typography variant="h5">
                    Cloud Solar installs and hosts your remote panels on our
                    licensed roofs and land; the solar power generated from our
                    distributed network is pooled on the cloud platform and sold
                    to the utility company, also known as The Barbados Light &
                    Power Co.
                  </Typography>
                </div>
              </div>
              <div className="how-it-step">
                <div className="step-content">
                  <div className="images-step mobile-img">
                    <GatsbyImage
                      image={data.step2.childImageSharp.gatsbyImageData}
                      alt="Cloud Solar step 2"
                    />
                  </div>
                  <Typography variant="h5">
                    {' '}
                    We calculate each clients’ solar power generation every
                    month based on the number of panels they own and we issue
                    the net solar energy credits directly to our clients’ Solar
                    Credits Account.
                  </Typography>
                </div>
                <div className="images-step mobile-img-hide">
                  <GatsbyImage
                    image={data.step2.childImageSharp.gatsbyImageData}
                    alt="Cloud Solar step 2"
                  />
                </div>
              </div>
              <div className="how-it-step">
                <div className="images-step mobile-img-hide">
                  <GatsbyImage
                    image={data.step3.childImageSharp.gatsbyImageData}
                    alt="Cloud Solar step 3"
                  />
                </div>
                <div className="step-content">
                  <div className="images-step mobile-img">
                    <GatsbyImage
                      image={data.step3.childImageSharp.gatsbyImageData}
                      alt="Cloud Solar step 3"
                    />
                  </div>
                  <Typography variant="h5">
                    Clients automatically receive their solar energy credits
                    directly from their account to their preferred financial
                    institution, on a monthly basis as a cash payment.
                  </Typography>
                </div>
              </div>
            </div>
            <div className="btn-wrap">
              <a className="button" href="https://app.cloud.solar/get-started/">
                GET STARTED
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
